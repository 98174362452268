<template>
	<validate-form tag="table" ref="form" :class="$style['ORDER_EXAM-form']">
		<wt-form-item label="复测类型：" name="复测类型" ref="formItem" tag="div"  :rules="'required'" lineHeight="30" class="start-time textarea-content" :showError="false">
			<span class="is-required" slot="required">*</span>
			<wt-textarea v-model="formValue.reason" placeholder="请输入复测原因" ></wt-textarea>
		</wt-form-item>
	</validate-form>
</template>
<script>
import {next} from "../status"
import formMixins from 'mixins/form'
export default {
  mixins:[formMixins],
  props:{
    order:{
      type:Object,
      default:() => {
        return {}
      }
    },
    status:String
  },
  data (){
    return{
      formValue:{}
    }
  },
	computed:{
    isTask (){
      return this.$route.query.isTask === 'true'
    },
  },
  methods:{
		 next (){
      let data = {
        orderIds: [this.order.id],
        billId: this.order.billId,
        action: this.status,
        orderStatusDetail:{
          action: this.status,
          orderStatusResult:"RETEST",
          reason:this.formValue.reason
        }
      }
			if (this.isTask) {
        data.taskId = this.$route.params.id
				data.billId = this.$route.query.billId
				data.productionTaskTrigger = true
				delete data.orderIds
      } 
      this.validate('formValue').then((formValue) => {
        return next.call(this, data)
      })
		 }
  }
}
</script>
<style module lang="less">
@import "~less/var.less";
.ORDER_EXAM-form{
	background-color: #fff;
	width: 100%;
	:global{
		.wt-form-list-item{
			display: flex;
			width: 100%;
			padding: 0 @page-padding;
			box-sizing: border-box;
			// border-bottom: 1Px solid @font-of-grey;
			.label-text{
				justify-content: flex-start;
			}
			.label-slot{
				width: 100%;
			}
			.nomal-value{
				width: 100%;
			}
			.label-content{
				padding: 0.213333rem 0
			}
			td{
				display: flex;
				align-items: flex-start;
				.is-required{
					text-align: center;
					padding: 0.213333rem 0;
				}
			}
		}
			.error-message{
				text-align: start;
				padding: 0 0.4rem !important;
			}
	}
}
</style>