/**
 * 根据当前状态得到下个状态
 * @param {*string} currentStatus 当前状态
 * 支持回调，eg：next(***).then(修改成功后的操作).catch(修改失败后的操作)
 */

import store from '@/store'
import Vue from "vue";
export function next(data, noForm) {
  let currentDispatch = 'geneOrderStatusUpdate'
  for (let i in data) { // 处理下字段值格式，比如将日期序列化成字符串，以符合接口所需格式，
    /* global _ */
    if (_.isDate(data[i])) {
      data[i] = formatDateObject(data[i])
    }
  }
  return new Promise((resolve, reject) => {
    store.dispatch('geneOrderStatusUpdate', data).then(response => {
      // this.$message.success('操作成功！');
      let info = new Set()
      let orderActionResults
      orderActionResults = response.data
      orderActionResults.forEach(ele => {
        if (ele.status !== 'SUCCESS') {
          if (ele.message) {
            info.add(ele.message)
          }
          if (ele.status === 'NEED_APPROVAL') {
            info.add('进入待处理')
          }
          if (ele.status === 'FAIL') {
            info.add('订单操作失败')
          }
          if (ele.status === 'NOT_EXECUTED') {
            info.add('操作未执行')
          }
          if (ele.status === 'VALIDATION_FAILED') {
            info.add('订单校验失败')
          }
        }
      })
      if (Array.from(info).length) {
        Vue.prototype.$toast.error(Array.from(info).toString())
      } else {
        // if (currentDispatch !== 'getSampleBoardStatusUpdate') {
        Vue.prototype.$toast.success('操作成功！')
        if (!noForm) {
          setTimeout(_ => {
            history.go(-1)
              // this.$emit('load')
          }, 1000)
        }
        // }
      }
      resolve(response)
    }).catch(err => {
      Vue.prototype.$toast.error(err)
        // reject(err)
    })
  })
}