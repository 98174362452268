<template>
  <div :class="$style[classes]">
		<list-header class="list-header" v-model="params.searchKey" v-if="!noShowheader" searchPlaceholder="请输入任务单编号或委托单编号搜索"></list-header>
		<div class="trust-statistics-all">
      <statistics-data class="trust-statistics" :data="statisticData" :labels="statisticLabels" v-if="!noShowheader"></statistics-data>
			<div class="trust-list-message">
      <div class="trust-list-title">
        <div class="title">任务单</div>
        <select-picker v-model="params.status" :data="filterData" @selectHandle="selectHandle"></select-picker>
      </div>
      <!-- <trust-list :params="params"></trust-list> -->
			<task-list :params="params" :canSelect="canSelect" :billId="billId" ></task-list>
			</div>
    </div>
	</div>
</template>

<script>
// 组件class前缀
const prefixCls = 'pt-task-order'
import {taskStatusList} from "json/task-options"
import taskList from "./pt-task-list/withData"
export default {
  name: 'pt-task-order',
  components:{
    taskList
  },
  computed: {
    classes () {
      return `${prefixCls}`
    },
    filterData () {
      return taskStatusList.map((item) => ({
        text: item.label,
        value: item.value
      }));
    }
  },
  props:{
    statisticData:{
      type:Object,
      default:() => {}
    },
    noShowheader:Boolean,
    canSelect:Boolean,
    billId:String
  },
  data (){
    return {
      statisticLabels:[
        {
          label:"任务单",
          key:'taskCount'
        },
        {
          label:"加急任务",
          key:'taskUrgentCount'
        },
        {
          label:'逾期任务',
          key:'taskOverDueCount'
        }
      ],
      params:{
				status:this.$route.query.status
			}
    }
  },
  methods:{
		selectHandle(val,index,text){
			this.$delete(this.params,'excludeStatus')
		}
  },
	created(){
		if(this.$route.query&&this.$route.query.status){
			this.params.status=this.$route.query.status
		}else{
			this.params.excludeStatus=['PRODUCTION_TASK_COMPLETED']
		}
	}
}
</script>

<style module lang="less">
/* 已启用css module， template中请使用$style.className添加class */
/* 务必遵循css BEM原则 */
@import "~less/var.less";
.pt-task-order{
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow-y: hidden;
  :global {
		.list-header{
			display: flex;
		}
    .trust-statistics {
			margin-top: 40px;
			display: flex;
			justify-content: space-around
		}
		.trust-list-message{
			display: flex;
			flex-direction: column;
			flex: 1;
			overflow-y: hidden
		}
    .trust-statistics-all {
			padding: 0 @page-padding;
			display: flex;
			flex-direction: column;
			flex: 1;
			overflow-y: hidden
    }
    .trust-list-title {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      .title {
        font-weight: 600;
      }
    }
  }
}
</style>
