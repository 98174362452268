<template>
  <wt-loadmore ref="loadmore" :on-pulldown="load" :on-pullup="load" :class="$style['pt-task-list']">
    <template slot-scope="{ data }">
			<cube-radio-group  v-model="checkList" v-if="canSelect">
				<cube-radio :option="item.id" v-for="(item, index) in data" :key="index">
					<div :class="$style['list-item']">
					<list-item :data="item" @click.native="goDetail(item.id)" :canSelect="canSelect"></list-item>
					</div>
				</cube-radio>
			</cube-radio-group>
      <div
        :class="$style['list-item']"
        v-for="(item, index) in data"
        :key="index"
				v-else
      >
        <list-item :data="item" @click.native="goDetail(item.id)" :canSelect="canSelect"></list-item>
      </div>
    </template>
  </wt-loadmore>
</template>

<script>
// 组件class前缀
const prefixCls = 'pt-task-list'
import listItem from "./item"
import emitter from '@/mixins/emitter'
export default {
  name: 'pt-task-list',
  mixins:[emitter],
  computed: {
    classes () {
      return `${prefixCls}`
    },
    loadmore (){
      return this.$refs.loadmore
    }
  },
  props:{
    canSelect:Boolean,
    data:{
      type:Array,
      default:() => {
        return []
      }
    }
  },
  data (){
    return {
      checkList:null
    }
  },
  watch:{
    checkList:{
      handler:function (val){
        let data = this.data.filter(item => {
          return item.id === val
        })
        this.dispatch('pt-task-order', 'selectChange', data[0])
      }
    }
  },
  components:{
    listItem
  },
  methods:{
    load (page, next){
      this.$emit('load', page, next)
    },
    showRetry () {
      this.loadmore.showRetry();
    },
    refresh (val, row){
      this.loadmore.refresh(val, row)
    },
    goDetail (id){
      this.$router.push(`/task-order-detail/${id}`)
    }
  }
}
</script>

<style module lang="less">
/* 已启用css module， template中请使用$style.className添加class */
/* 务必遵循css BEM原则 */
.pt-task-list {
  margin-top: 20px;
}
.list-item {
	padding-bottom: 20px;
		flex:1
}
</style>
