<template>
<div :class="$style['pt-sample-detail']">
	<component ref="form" :is="status" :status="status" :order="order" :bill="bill" :isAbnormal="isAbnormal" :isCreat="isCreat" :reasonOptions="reasonOptions"></component>
	<div :class="$style['next-button-div']">
	<wt-button @click="next" primary :class="$style['next-button']" v-if="showTextButton">{{submitText}}</wt-button>
	</div>
	<div :class="$style['sample-footer']" v-if="showFooter">
		<div class="footer-button-div">
		<wt-button primary @click="next">分派任务</wt-button>
		</div>
	</div>
</div>
</template>
<script>
import ORDER_RECEIVED from "./form/ORDER_RECEIVED"
import ORDER_ASSIGN from "./form/ORDER_ASSIGN"
import ORDER_EXAM from "./form/ORDER_EXAM"
export default {
  props:{
    status:String,
    id:String,
    data:{
      type:Object,
      default:() => {}
    },
    order:{
      type:Object,
      default:() => {}
    },
    isAbnormal:Boolean,
    reasonOptions:{
      type:Array,
      default:() => []
    },
    bill:{
      type:Object,
      default:() => {
        return {}
      }
    }
  },
  components:{
    ORDER_RECEIVED, ORDER_ASSIGN, ORDER_EXAM
  },
  computed:{
    submitText (){
      return this.status === 'ORDER_ASSIGN' ? '确认分派' : '确认提交'
    },
    showFooter (){
      return this.status === 'ORDER_ASSIGN' && !this.isCreat
    },
    isCreat (){
      return this.$route.query.isCreat === 'true'
    },
    showTextButton (){
      let hasButtonStatus = ['ORDER_ASSIGN', 'ORDER_RECEIVED']
      let textButton = false
      switch(this.status){
      case 'ORDER_ASSIGN':
        if(this.isCreat){
          textButton = true
        }
        break
      case 'ORDER_RECEIVED':
      case 'ORDER_EXAM':
        textButton = true
        break
				
      default:
        textButton = false
        break
      }
      return textButton
    }
  },
  data (){
    return {
    }
  },
  methods:{
    next (){
      this.$refs.form.next()
    }
  },
  created (){
  }
}
</script>
<style module lang="less">
.next-button-div{
	padding: 20px;
	:global{
	.cube-btn{
		width:100%
	}

	}
}
.pt-sample-detail{
	height: 100%;
	overflow-y: hidden
}
.next-button{
	// margin-top: 20px;
}
.sample-footer{
	position: fixed;
	width: 100%;
	bottom: 0;
	z-index: 1000;
	:global{
		.footer-button-div{
		height: 100%;
		display: flex;
		justify-content:end;
		align-items: center;
		background-color: #fff;
		padding: 10px;
		}
	}
}
</style>