<template>
  <pt-sample-status-data class="pt-sample-detail__page" :id="id" :status="status"></pt-sample-status-data>
</template>

<script>
import PtSampleStatusData from 'cps//pt-sample-detail/com/withData'
export default {
  name: 'pt-sample-status__view',
  components: {
    PtSampleStatusData
  },
  props:{
    id:String,
    status:String
  }
}
</script>
