<template>
  <pt-task-list class="pt-task-list__withData" @load="load" ref="loadmore" :canSelect="canSelect" :data="data"></pt-task-list>
</template>

<script>
import PtTaskList from './index'
export default {
  name: 'pt-task-list__withData',
  components: {
    'pt-task-list': PtTaskList
  },
  props:{
    params:{
      type:Object,
      default:() => {
        return {}
      }
    },
    canSelect:Boolean,
    billId:String
  },
  computed:{
    loadmore (){
      return this.$refs.loadmore
    }
  },
  watch:{
    params:{
      handler:function (val){
				if(val.status==='ALL'){
					this.$delete(this.params,'status')
					this.$router.push({name:"task-order",query:{}})
				}else{
					this.$router.push({name:"task-order",query:{status:val.status}})
				}
        this.loadmore.refresh(false, true)
      },
      deep:true
    }
  },
  data (){
    return{
      data:[]
    }
  },
  methods:{
    load (page, next){
      let param = {
        ...this.params,
        pageable: {
          page: page,
          size: 20
        }
      };
      if(this.billId){
        param.billId = this.billId
      }
      this.$store
        .dispatch("getTaskList", param)
        .then((response) => {
          let { data, totalNumber } = response;
          next(data, totalNumber);
          this.data = data
        })
        .catch((error) => {
          this.loadmore.showRetry();
          this.$toast.error(error);
        });
    }
  }
}
</script>
