<template>
  <div :class="$style['task-list-item']" :style="{ borderColor: showColor }">
    <div class="name-message">
      <div :style="{ width: data.urgent && data.overDue ? '45%' : '50%' }" class="lab-exam-item-name" >
        {{ data.itemNames }}
      </div>
      <div class="right-message">
        <status-tag class="status-tag" v-if="data.urgent" background-color="#FF2D2D" ></status-tag>
        <status-tag class="status-tag" v-if="data.overDue" background-color="#F7B500" text="逾期"></status-tag>
        <div :style="{color:statusColor}">{{ data.status_text }}</div>
      </div>
    </div>
		<div :class="$style['content']">
			<div class="content-left">
				<table>
					<tr>
						<td>任务单编号：</td>
						<td>{{data.serialNumber}}</td>
					</tr>
					<tr>
						<td>委托单编号：</td>
						<td>{{data.billCode}}</td>
					</tr>
					<tr>
						<td>样品名称：</td>
						<td>{{data.orderNames}}</td>
					</tr>
					<tr>
						<td>实验人员：</td>
						<td>{{data.labTester&&data.labTester.name}}</td>
					</tr>
					<tr>
						<td>分派人：</td>
						<td>{{data.creator}}</td>
					</tr>
					<tr>
						<td>任务期限：</td>
						<td>{{data.term}}</td>
					</tr>
					<tr>
						<td>交付方式：</td>
						<td>{{data.delivery_type}}</td>
					</tr>
				</table>
				</div>
		</div>
  </div>
</template>
<script>
export default {
  props:{
    data:{
      type:Object,
      default:() => {
        return {}
      }
    }
  },
  computed: {
    statusColor (){
      return this.data.status === 'BILL_COMPLETED' ? '#ADADAD' : "#409EFF"
    },
    showColor () {
      return this.data.urgent ? "#FF2D2D" : (this.data.overDue ? "#F7B500" : this.statusColor)
    }
  }
}
</script>
<style module lang="less">
@import "~less/var.less";
.task-list-item{
  background-color: #fff;
  padding: 15px;
  border-left: solid 5px @main-color;
  border-radius: 5px;
  :global {
    .name-message {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        align-items: center;
      }
    }
    .lab-exam-item-name {
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
    .right-message {
      display: flex;
      padding-left: 5px;
    }
    .status-tag {
      margin-right: 5px;
    }
  }
}
.content{
	display:flex;
	:global{
		.content-left{
		padding-top:8px;
		}
	}
	table{
		font-size:@font-size-tip;
		td{
			padding:5px 0;
			text-align:left
		}
		tr{
			:first-child{
				color:@font-of-grey;
				width: 6em;
			}
		}
	}

}
</style>