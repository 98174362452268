<template>
<div :class="$style['more-input-data-form']">
	<div class="item">
		<wt-form-item
		:label="label"
		ref="formItem"
		:lineHeight="lineHeight"
		:widthMax="widthMax"
	>
	<slot slot="required"/>
	<template v-for="(item,index) in data">
		<div class="input-item" :key="index">
			<wt-input placeholder="请填写您的快递单号" v-model="item.value"></wt-input>
			<i class="iconfont icon-shanchu" style="margin-left:10px" @click="deleteItem(index)"></i>
		</div>
	</template>
	</wt-form-item>
	</div>
	<div class="add" @click="addCourierNumber">添加一条</div>
</div>
</template>
<script>
export default {
  props:{
    label:String,
    errorMessage:String,
    lineHeight:[Number, String],
    widthMax:[Number, String]
  },
  data (){
    return {
      data:[{value:""}]
    }
  },
  watch:{
    data:{
      handler:function (val) {
        let data = val.map(item => {
          return item.value
        })
        this.$emit('input', data)
      },
      deep:true
    }
  },
  methods:{
		deleteItem(index){
      if (index === 0 && this.data.length === 1) {
        this.$toast.error('请至少保留一条可填写数据')
        return
      }
      this.$confirm('确定要删除该条数据?').then(_ => {
        this.data.splice(index, 1)
      })
		},
    addCourierNumber (){
      let validate = true
      this.data.forEach(item => {
        if(!item.value){
          validate = false
        }
      });
      if(validate){
        this.data.push({value:""})
      }else{
        this.$toast.error(this.errorMessage || '请输入快递号');
				
      }
    }
  }
}
</script>
<style module lang="less">
@import "~less/var.less";
.more-input-data-form{
	:global{
		.item{
			padding: 0 @page-padding
		}
		.add{
			color: @main-color;
			border-top: 1Px solid @font-of-grey;
			padding: 10px;
		}
		.input-item{
			display: flex;
			.wt-input{
				width: 100%
			}
			.icon-shanchu{
				align-items: center;
    		display: flex;
			}
		}
	}
}
</style>