<template>
<pt-sample-status :status="status" :id="id" :data="data" :order="order" :reasonOptions="reasonOptions" :isAbnormal="isAbnormal" :bill="bill" v-if="!loading"></pt-sample-status>
</template>
<script>
import ptSampleStatus from "./index";
export default {
  components:{
    ptSampleStatus
  },
  props:{
    status:String,
    id:String
  },
  data (){
    return {
      data:{},
      order:{},
      bill:{},
      reasonOptions:[],
      loading:false
    }
  },
  computed:{
    isAbnormal (){
      return this.$route.query.isAbnormal === 'true'
    },
    isTask (){
      return this.$route.query.isTask === 'true'
    },
    AbnormalReasonType (){
      let type 
      switch(this.status){
      case 'ORDER_RECEIVED':
        type = 'LAB_SAMPLES_RECEIVED__UNQUALIFIED_REASON'
        break
      case 'DATA_RETURN':
        type = 'DATA_RETURN_UNQUALIFIED_REASON'
        break
      case 'ORDER_ASSIGN':
        type = 'LAB_SAMPLES_RECEIVED__UNQUALIFIED_REASON'
        break
      case 'ORDER_EXAM':
        type = 'LAB_DNA_TESTED_UNQUALIFIED_REASON'
        break
      case 'ORDER_GET':
        type = 'LAB_SAMPLES_RECEIVED__UNQUALIFIED_REASON'
        break
      default:
        type = "DATA_RETURN_UNQUALIFIED_REASON"
        break
      }
      return type
    }
  },
  methods:{
    getSampleDetail (){
      this.$loading()
      this.loading = true
      this.$store.dispatch('getSampleDetail', this.id).then(res => {
        this.$loading.hide()
        this.loading = false
        this.data = res
        let {order, bill, status} = res
        this.order = order
        this.bill = bill
      }).catch(err => {
        this.$loading.hide()
        this.loading = false
        this.$toast.error(err || '获取样品信息失败')
      })
    },
    getAbnormalReason () {
      this.$loading()
      this.$store.dispatch('getAbnormalReason', this.AbnormalReasonType).then(res => {
        res.forEach(item => {
          item.text = item.name
        });
        this.reasonOptions = res
        this.$loading.hide()
      }).catch(err => {
        this.$loading.hide()
        this.$toast.error(err || '获取异常原因失败')
      })
    }
  },
  created (){
    if(!this.isTask){
      this.getSampleDetail()
    }
    
    if(this.isAbnormal){
      this.getAbnormalReason()
    }
  }
}
</script>
<style module lang="less">
</style>