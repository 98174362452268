<template>
	<div :class="$style['ORDER_ASSIGN']" :style="{height:!isCreat?'100%':'auto'}">
		<validate-form tag="table" ref="form" :class="$style['ORDER_ASSIGN-form']" v-if="isCreat">
			<wt-form-item label="开始日期：" name="开始日期" ref="formItem" tag="div" lineHeight="30" class="start-time" :showError="false">
			<!-- <span class="is-required" slot="required">*</span> -->
			<pt-date-picker v-model="formValue.startTime" title="开始日期"></pt-date-picker>
			</wt-form-item>
			<wt-form-item label="目标日期：" name="目标日期" ref="formItem" tag="div" lineHeight="30" class="start-time"  :showError="false">
			<!-- <span class="is-required" slot="required">*</span> -->
			<pt-date-picker v-model="formValue.endTime" title="目标日期"></pt-date-picker>
			</wt-form-item>
			<wt-form-item label="实验人员：" name="实验人员" ref="formItem" tag="div"  :rules="'required'" lineHeight="30"  :showError="false">
					<span class="is-required" slot="required">*</span>
				<wt-select :data="labTesters" v-model="formValue.labTester" title="选择实验人员" placeholder="请选择实验人员"></wt-select>
			</wt-form-item>
			<wt-form-item label="备注：" class="start-time comment textarea-content" lineHeight="30" :showError="false">
				<wt-textarea v-model="formValue.comment" :indicator="false" placeholder="请填写备注"  :autoExpand="true"></wt-textarea>
			</wt-form-item>
		</validate-form>
		<task-order v-else :noShowheader="true" :canSelect="true" @selectChange="selectChange" :billId="bill.id"></task-order>
	</div>
</template>
<script>
import taskOrder from "cps/pt-task-order"
import {next} from "../status"
import formMixins from 'mixins/form'
import {getTimeFromBillCode} from "@/utils/page-formt/formt.js"
export default {
  mixins: [formMixins],
  components:{
    taskOrder
  },
  props:{
    order:{
      type:Object,
      default:() => {
        return {}
      }
    },
    status:String,
    bill:{
      type:Object,
      default:() => {
        return {}
      }
    },
    isCreat:Boolean
  },
  data (){
    return {
      selectedTask:null,
      formValue:{},
      labTesters:[]
    }
  },
  watch:{
    'bill.billCode':{
      handler:function (val){
        let {startTime, endTime} = getTimeFromBillCode(val)
        this.formValue.startTime = startTime
        this.formValue.endTime = endTime
      },
      immediate:true
    },
		'bill':{
			handler:function(val){
				this.formValue.comment=val.remarks
			},
			deep:true,
			immediate:true
		}
  },
  computed:{
  },
  methods:{
    systemCustomerList (){
      let data = {
        pageable:{page: 0, size: 99999},
        role:['ROLE_RT_EX', 'ROLE_EX']
      }
      this.$store.dispatch('systemCustomerList', data).then(res => {
        console.log('res===', res)
        res.forEach(item => {
          item.value = item.id
          item.text = item.firstName
        });
        this.labTesters = res
      }).catch(err => {
        this.$toast.error(err || '获取实验人员列表失败')
      })
    },
    next (){
      let data = {
        orderIds: [this.order.id],
        billId: this.order.billId,
        action: this.status,
        orderStatusDetail:{
          action: this.status,
          productionTaskDetail:this.isCreat?{
            startTime:this.formValue.startTime,
            endTime:this.formValue.endTime
          }:this.selectedTask,
          comment:this.formValue.comment,
        }
      }
      let labTester = {}
      this.labTesters.forEach(item => {
        if(item.id === this.formValue.labTester){
          labTester.name = item.text
          labTester.organizationUserId = item.id
					labTester.signatureImageUrl = item.signatureImageUrl
        }
			});
			if(this.isCreat){
      data.orderStatusDetail.productionTaskDetail.labTester = labTester
			}
      if(this.isCreat){
				 this.validate('formValue').then((formValue) => {
          return next.call(this, data)
        })
      }else{
        if(this.selectedTask){
          return next.call(this, data)
        }else{
          this.$toast.error('请选择任务单')
        }
      }
    },
    selectChange (val){
			this.selectedTask = val
    }
  },
  created (){
    if(this.isCreat){
      this.systemCustomerList()
    }
  }
}
</script>
<style module lang="less">
@import "~less/var.less";
.ORDER_ASSIGN{
	height: 100%;
	overflow-y: hidden;
}
.ORDER_ASSIGN-form{
	background-color: #fff;
	width: 100%;
	:global{
		.start-time{
			.label-content{
				width: 5.5em;
				padding-left: 3px!important;
			}
		}
		.wt-form-list-item{
			display: flex;
			width: 100%;
			padding: 0 @page-padding;
			box-sizing: border-box;
			border-bottom: 1Px solid @font-of-grey;
			.label-slot{
				width: 100%;
			}
			.nomal-value{
				width: 100%;
			}
			.label-content{
				padding: 0.213333rem 0
			}
			td{
				display: flex;
				align-items: center;
				.is-required{
					text-align: center
				}
			}
			&:last-child{
				border-bottom:none
			}
		}
		.comment{
			.label-text{
				align-items:flex-start;
				justify-content:flex-start;
			}
		}
	}
}
</style>