<template>
		<validate-form tag="table" ref="form" :class="$style['ORDER_RECEIVED']" >
			<wt-form-item label="异常原因：" name="异常原因" ref="formItem" tag="div"  :rules="'required'" lineHeight="30" v-if="isAbnormal" class="abnormak-reason"  :showError="false">
			<span class="is-required" slot="required">*</span>
				<wt-select :data="reasonOptions" v-model="form.reason" title="选择异常原因" placeholder="请选择异常原因"></wt-select>
			</wt-form-item>
			<more-input-data-form label="快递单号：" v-model="form.expressIds" lineHeight="30"  :showError="false">
				<span class="is-required" style="color:#fff!important">*</span>
			</more-input-data-form>
			<wt-date-picker ref="datePicker"></wt-date-picker>
		</validate-form>
</template>
<script>
import moreInputDataForm from "cps/page-base/more-input-data-form";
import {next} from "../status"
import formMixins from 'mixins/form'
const AbnormalReasonType = 'LAB_SAMPLES_RECEIVED__UNQUALIFIED_REASON'
export default {
  components:{
    moreInputDataForm
  },
  mixins: [formMixins],
  props:{
    status:String,
    order:{
      type:Object,
      default:() => {}
    },
    isAbnormal:Boolean,
    reasonOptions:{
      type:Array,
      default:() => []
    }
  },
  data (){
    return {
      form:{
        expressIds:[]
      },
      datePicker:null,
      options:[],
      widthMax:'80'
    }
  },
  methods:{
    setDate (){},
    showdatePicker (){
      this.$refs.datePicker.show()
    },
    next (){
      let data = {
        orderIds: [this.order.id],
        billId: this.order.billId,
        action: this.status,
        orderStatusDetail:{
          expressIds:this.form.expressIds,
          orderStatusResult:this.isAbnormal ? 'NOT_QUALIFIED' : 'QUALIFIED',
          reason:this.form.reason
        }
      }
      this.validate('formValue').then((formValue) => {
        return next.call(this, data)
      })
    }
  }
}
</script>
<style module lang="less">
@import "~less/var.less";
.ORDER_RECEIVED{
	background-color: #fff;
	width: 100%;
	:global{
		.wt-form-list-item{
			display: flex;
			.label-slot{
				width: 100%;
			}
			.nomal-value{
				width: 100%;
			}
			.label-content{
				padding: 0.213333rem 0
			}
			td{
				display: flex;
				align-items: center;
				justify-content:flex-start;
				.is-required{
					text-align: center
				}
			}
		}
		.abnormak-reason{
			padding: 0 @page-padding;
			border-bottom: 1Px solid @font-of-grey;
		}
	}
}
</style>